import {logHelper, tLogStyled} from 'utils/Logger';
import {SidePanelData} from 'webgl/types/SidePanelData';

declare const sidePanelData: { [key: string]: SidePanelData }; // imported from /public/editableContent.js, so no need to be compiled

// TODO FAKE DATA => USING DATA FROM public/editableContent.js
/*
const pointDescriptions_FAKE: { [key: string]: SidePanelData } = {
  'webgl.points.pt2': {
    marker: '2',
    title: `C'est quoi une machine ?`,
    description: `<p>Une machine est un équipement qui peut :
    <ul><li><strong>être de toutes dimensions</strong> (d'une ponceuse portative jusqu'à une ligne de fabrication de la taille d'un bâtiment)</li>
    <li><strong>exercer diverses fonctions</strong> (usinage de métaux ou bois, manutention, impression, assemblage, ...)</li>
    <li><strong>être mobile</strong> (chariot élévateur, véhicule autoguidé, ...)</li>
    <li><strong>faire du levage de charge ou de personne</strong> (pont roulant, plateforme élévatrice de personnel, ...)</li>
    <li><strong>etc.</strong></li>
</p>`,
    carrouselItems: [{url: 'img1'}, {url: 'img2'}, {url: 'img3'}]
  },
  'webgl.points.pt3': {
    marker: '3',
    title: `Adéquation machine et travail à réaliser`,
    description: `
    <p>Acquérir une machine nécessite de s'assurer de son adéquation avec le travail à réaliser. Pour cela, il convient :
    <ul><li><strong>de définir et d'analyser le besoin :</strong></li>
      <ul><li>opérations à accomplir (production, réglage, maintenance, nettoyage, …)</li>
      <li>possibilités d'évolution</li>
      <li>ambiance de travail (froid, humidité, poussières, ...)</li>
      <li>caractéristiques des matériaux travaillés et produits utilisés/émis</li></ul>
      <li>d'associer <strong>l'ensemble des acteurs</strong> à la démarche (opérateurs, maintenance, encadrement, …)</li>
      <li>de s'assurer que l'activité attendue des opérateurs est <strong>compatible avec les conditions d'utilisation</strong> définies par le fabricant</li>
      <li>de vérifier que <strong>la machine est appropriée</strong> aux travaux que l'utilsateur prévoit. L'usage attendu (et les limites) de la machine doivent être rappelés dans la notice d'instructions</li>
      </li></p>`,
    carrouselItems: [{url: 'https://picsum.photos/1920/1080', caption: 'légende image 1'},
      {url: 'https://picsum.photos/512/512', caption: 'légende image 2'},
      {url: 'https://picsum.photos/720/1280'},
      {url: 'https://picsum.photos/2048/128', caption: 'légende image 4'}
    ]
  },
  'webgl.points.pt4': {
    marker: '4',
    title: `Risques générés par les machines`,
    description: `<p>Une machine présente des risques :
    <ul>
    <li><strong>de toute nature</strong> (pas uniquement mécanique mais aussi électrique, chimique, lié au bruit, au rayonnement, ...)</li>
    <li><strong>pour chaque situation de travail</strong></li>
    <li><strong>dans toutes les phases d'utilisation</strong> (production, maintenance, nettoyage, ...)</li>
    </ul></p>`,
    carrouselItems: [{url: 'img1'}]
  },
  'webgl.points.pt5': {
    marker: '5',
    title: `"CE" ne veut pas dire conformité, ni sécurité !`,
    description: `<p>Le marquage "CE" est simplement l'engagement visible du fabricant qu'en principe sa machine respecte les exigences réglementaires en vue de sa libre circulation au sein de la CEE. Ce marquage s'accompagne d'une déclaration CE de conformité rédigée par le fabricant. <strong>Ce déclaratif ne garantit pas à lui seul la conformité de l'équipement</strong> (principe de l'auto-certification).</p>
    <p>L'employeur a <strong>l'obligation de mettre à disposition des machines sûres</strong>, conformes et adaptées au travail à effectuer (Code du Travail). Pour ce faire, l'employeur peut se faire aider par une tierce partie compétente (bureau de contrôle, conseil, …) pour réaliser une vérification de l'état de conformité de la machine.</p>
    <p>
    <strong>Conformité ne veut pas dire absence de risques :</strong>
    <ul>
    <li>partie travaillante accessible sur les machines à bois traditionnelles</li>
    <li>chariot élévateur utilisé pour l'élévation d'une personne</li>
    </ul>
    </p>`
  },
  'webgl.points.pt6': {
    marker: '6',
    title: `Bibliographie`,
    description: `Brochures :
    <ul>
    <li><strong>Formation machines</strong></li>
    <li><strong>Fiches MAP</strong></li>
    <li><strong>Réseau et correspondances ET</strong></li>
    <li><strong>Fiches traitement pb de prévention</strong></li>
    <li><strong>MECAPREV</strong></li>
    <li><strong>Aides SPTPE</strong></li>
</ul>`
  },
  'webgl.points.pt7': {
    marker: '7',
    title: `Pour aller plus loin...`,
    description: `<p>La question à (se) poser pour approfondir le sujet machine en caisse :
    <ul><li>Les règles de <strong>mise sur le marché</strong></li>
    <li>La <strong>démarche de modification</strong> machine</li>
    <li>Les solutions techniques pour <strong>sécuriser les machines</strong></li></ul>
    </p>`
  },
  'webgl.contact': {
    marker: '',
    title: `email@email.com`,
    description: `sujet de l'email`
  },
  'webgl.credits': {
    marker: '',
    title: `Crédits`,
    description: `<div style="text-align: center">
<p><strong>Contenu rédactionnel</strong></p>
<p>Groupe de Travail "Machines"<br/> (groupe issu de la plénière des correspondants<br/> Équipements de travail du réseau Prévention)</p>

<p><strong>Éditeur</strong></p>
<p><img src="./assets/img/logo_carsat.jpg" alt="logo CARSAT" style="height: 6rem; width: auto;"/> </p>

<p><strong>Création des contenus et graphiques 3D</strong></p>
<p><img src="./assets/img/logo_tribia.png" alt="logo TRIBIA" style="height: 6rem; width: auto;"/><br/><i>www.tribia.net</i></p>

</div>`
  },
};
*/

export const getSidePanelContent__JSON = async (state?: string): Promise<SidePanelData> => {
  tLogStyled('[getSidePanelContent__JSON(state)] using json data ', logHelper.warning, state);

  return new Promise<SidePanelData>((resolve, reject) => {
    if (!state) {
      reject('missing `state`');
    } else {

      setTimeout(() => resolve(sidePanelData[state]), 0);

    }
  });
};

// export const getSidePanelContent__FAKE = async (state?: string): Promise<SidePanelData> => {
//   tLogStyled('[getSidePanelContent__FAKE(state)] using fake data ', logHelper.warning, state);
//
//   return new Promise<SidePanelData>((resolve, reject) => {
//     if (!state) {
//       reject('missing `state`');
//     } else {
//
//       setTimeout(() => resolve(pointDescriptions_FAKE[state]), 0);
//
//     }
//   });
// };