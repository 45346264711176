import {useRef} from 'react';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader';
// import {KTX2Loader} from 'three/examples/jsm/loaders/KTX2Loader';
// TODO ADD MESHOPT & KTX2 DECODER!

const useGLTFLoader = () => {
  const gltfLoader = useRef(new GLTFLoader());

  const dracoLoader = useRef(new DRACOLoader());
  dracoLoader.current.setDecoderPath('./assets/draco/');
  gltfLoader.current.setDRACOLoader(dracoLoader.current);

  // const ktx2Loader = useRef(new KTX2Loader());
  // ktx2Loader.current.setTranscoderPath('./assets/basis/');
  // gltfLoader.current.setKTX2Loader(ktx2Loader.current);

  return gltfLoader.current;
};

export {useGLTFLoader};