export const FSMStates = {
  notLoaded: 'notLoaded',
  loading: 'loading',
  loaded: 'loaded',
  parsing: 'parsing',
  parsed: 'parsed',
  loadingError: 'loadingError',
  hidden: 'hidden',
  // homepage: 'homepage',


  webgl: {
    main: 'webgl.main',
    pourquoi: {
      encoffrement: 'webgl.pourquoi.encoffrement'
    },
    evaluer: 'webgl.evaluer',
    isoler: 'webgl.isoler',
    isoler_sub: {
      a: 'webgl.isoler_sub.a',
      b: 'webgl.isoler_sub.b',
      b_sub: {
        zoom: 'webgl.isoler_sub.b_sub.zoom'
      },
      c: 'webgl.isoler_sub.c',
      d: 'webgl.isoler_sub.d',
      d_sub: {
        zoom: 'webgl.isoler_sub.d_sub.zoom'
      },
    },
    decoupler: 'webgl.decoupler',
    decoupler_sub: {
      a: 'webgl.decoupler_sub.a',
      b: 'webgl.decoupler_sub.b'
    },
    cumul: 'webgl.cumul',
    main_sub: {
      savoirPlus: 'webgl.main_sub.savoirPlus'
    },
    // credits: 'webgl.credits'
  },


  // below not used, kept for backward compatibility
  line: {
    default: 'line.default'
    // filled dynamically when custom line config is received
  } as { [p: string]: any }
};
