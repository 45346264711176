import React, {useCallback} from 'react';
import {StepListItem as StepListItemType} from 'webgl/types/StepListItem';

import './StepListItem.scss';
import {FSMStore} from 'webgl/stores';

type StepListItemProps = {
  item: StepListItemType;
  highlight?: boolean;
}

const StepListItem: React.FC<StepListItemProps> = ({item, highlight = false}) => {
  const setFSMState = FSMStore(state => state.setFSMState);
  
  const setState = useCallback(() => {
    if (item.state)
      setFSMState(item.state);
  }, [item.state, setFSMState]);
  
  return (
    <div className={`step-list-item${highlight ? ' highlighted': ''}`} onClick={setState}>
      <svg className="step-list-item-marker">
        <circle id="backCircle"/>
        <circle id="frontCircle"/>
        <text x="50%" y="50%" dy=".3em">{item.marker}</text>
      </svg>
      <div className={'step-list-title'}>{item.title}</div>
    </div>
  );

}

export default StepListItem;