import {useEffect, useState} from 'react';
import {Vector2} from 'three';

export enum ForcedAngle {
  topLeft = 'topLeft',
  top = 'top',
  topRight = 'topRight',
  right = 'right',
  bottomRight = 'bottomRight',
  bottom = 'bottom',
  bottomLeft = 'bottomLeft',
  left = 'left'
};

export const useAnnotationForcedAngle = (annotationForcePosition?: string): [number | null, Vector2] => {
  const [forcedAngle, setForcedAngle] = useState<number | null>(-Math.PI / 2); // "top" instead of null by default
  const [forcedPercentages, setPercentages] = useState<Vector2>(new Vector2(-50, -100));

  useEffect(() => {
    let _angle: number | null;
    let _percentages: Vector2;
    switch (annotationForcePosition) {
      case ForcedAngle.topLeft:
        _angle = (-3 * Math.PI / 4);
        _percentages = new Vector2(-100, -100);
        break;
      // case 'top': // DEFAULT
      //   _angle = (-Math.PI / 2);
      //   _percentages = new Vector2(-50, -100);
      //   break;
      case ForcedAngle.topRight:
        _angle = (-Math.PI / 4);
        _percentages = new Vector2(0, -100);
        break;
      case ForcedAngle.right:
        _angle = (0);
        _percentages = new Vector2(0, -50);
        break;
      case ForcedAngle.bottomRight:
        _angle = (Math.PI / 4);
        _percentages = new Vector2(0, 0);
        break;
      case ForcedAngle.bottom:
        _angle = (Math.PI / 2);
        _percentages = new Vector2(-50, 0);
        break;
      case ForcedAngle.bottomLeft:
        _angle = (3 * Math.PI / 4);
        _percentages = new Vector2(-100, 0);
        break;
      case ForcedAngle.left:
        _angle = (Math.PI);
        _percentages = new Vector2(-100, -50);
        break;
      case ForcedAngle.top:
      default:
        _angle = (-Math.PI / 2);
        _percentages = new Vector2(-50, -100);
        break;
    }

    setForcedAngle(_angle);
    setPercentages(_percentages);
  }, [annotationForcePosition]);

  return [forcedAngle, forcedPercentages];
};