import create from 'zustand';
import {Color, MathUtils, Vector3} from 'three';
import {getSolarAngles01} from 'webgl/helpers/solarAngles';
import {getColorFromElevation} from 'webgl/helpers/temperatureColors';
import {DefaultValues} from 'webgl/types/DefaultValues';
import {Season} from 'webgl/types/season';

/**
 * From polar to cartesian coords
 * @param elevation
 * @param azimuth
 * @param vector
 */
const calcPosFromAngles = (elevation: number, azimuth: number, vector = new Vector3()): Vector3 => {
  const phi = MathUtils.degToRad(90 - elevation);
  const theta = MathUtils.degToRad(-azimuth);

  vector.setFromSphericalCoords(1, phi, theta).multiplyScalar(100);
  // console.log(vector);

  return vector;
};

export type SkyRigStoreProps = {
  season: Season
  time: number

  sunPosition: Vector3
  lightColor: Color
  elevation: number
  azimuth: number
  azimuthOffset: number

  setSeason: (season: Season) => void
  setTime: (time: number) => void
  // setElevation: (elevation: number) => void
  // setAzimuth: (azimuth: number) => void
  setAzimuthOffset: (offset: number) => void

  hideSkyControls: boolean
  setHideSkyControls: (hidden: boolean) => void
}

export const skyRigStore = create<SkyRigStoreProps>((set, get) => ({
  season: DefaultValues.skyRigSeason,
  time: DefaultValues.skyRigTime,

  sunPosition: new Vector3(),
  lightColor: getColorFromElevation(getSolarAngles01(DefaultValues.skyRigTime, DefaultValues.skyRigSeason)[0]),
  elevation: getSolarAngles01(DefaultValues.skyRigTime, DefaultValues.skyRigSeason)[0],
  azimuth: getSolarAngles01(DefaultValues.skyRigTime, DefaultValues.skyRigSeason)[1],
  azimuthOffset: 180,

  setSeason: (season) => {
    const [_elevation, _azimuth] = getSolarAngles01(get().time, season);

    set({
      season: season,
      sunPosition: calcPosFromAngles(_elevation, _azimuth + get().azimuthOffset),
      elevation: _elevation,
      azimuth: _azimuth,
      lightColor: getColorFromElevation(_elevation)
    });
  },
  setTime: (time) => {
    const [_elevation, _azimuth] = getSolarAngles01(time, get().season);

    set({
      time: time,
      sunPosition: calcPosFromAngles(_elevation, _azimuth + get().azimuthOffset),
      elevation: _elevation,
      azimuth: _azimuth,
      lightColor: getColorFromElevation(_elevation)
    });
  },
  // setElevation: (elevation) => set({
  //   elevation: elevation,
  //   sunPosition: calcPosFromAngles(elevation, get().azimuth),
  //   lightColor: getColorFromElevation(elevation)
  // }),
  // setAzimuth: (azimuth) => set({
  //   azimuth: azimuth,
  //   sunPosition: calcPosFromAngles(get().elevation, azimuth)
  // }),

  setAzimuthOffset: (offset) => {
    // TODO
    console.log('azimuthOffset', offset);
    // set({azimuthOffset: offset})
  },

  hideSkyControls: false,
  setHideSkyControls: (hidden) => set({hideSkyControls: hidden})
}));