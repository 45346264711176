import React, {Dispatch, SetStateAction, useCallback, useEffect, useRef, useState} from 'react';
import {Object3D} from 'three';
import {Html} from '@react-three/drei/web/Html';
import {sizeClassStore} from 'stores';
import {
  useAnnotationForcedAngle, useAnnotationHandleExpand,
  useAnnotationHandleOutline,
  useAnnotationPositioning,
  useGetObjectCenter,
  useHandleGotoState
} from 'webgl/hooks';

import './AnnotationBase.scss';
import './AnnotationDangerClick.scss';
import {getAnnotationData__JSON} from 'services/getAnnotationData';

type AnnotationProps = {
  object: Object3D,
  expandedUuidStateArray: [string, Dispatch<SetStateAction<string>>]
}

const AnnotationDangerClick: React.FC<AnnotationProps> = ({object, expandedUuidStateArray}: AnnotationProps) => {
  const sizeClass = sizeClassStore(state => state.sizeClass);

  const [markerChar, setMarkerChar] = useState<string | undefined>(); // TODO retrieve from backend
  const [markerTooltip, setTooltip] = useState<string | undefined>(); // TODO retrieve from backend

  const isPointerDown = useRef<boolean>(false);
  const [isHovered, setHovered] = useState<boolean>(false);
  const [isExpanded, handleExpand] = useAnnotationHandleExpand(object, expandedUuidStateArray, markerTooltip);

  const [distanceMultiplier] = useState<number | undefined>(0);

  const position = useGetObjectCenter(object);
  const annotationAngle = useAnnotationForcedAngle(object.userData.tags?.annotationForcePosition);
  const handleGotoState = useHandleGotoState(object);
  const handleOutline = useAnnotationHandleOutline(object);
  const [annotationRef, , overrideCalculatePosition] = useAnnotationPositioning(...annotationAngle, distanceMultiplier, true);

  useEffect(() => {
    // TODO setNumber & setTitle
    getAnnotationData__JSON(object)
      .then(res => {
        setMarkerChar(res.marker);
        setTooltip(res.description);
      });
  }, [object]);

  const onPointerEnter = useCallback(() => setHovered(true), []);
  const onPointerLeave = useCallback(() => setHovered(false), []);
  const onPointerDown = useCallback(() => isPointerDown.current = true, []);
  const onPointerUp = useCallback(() => {
    if (!isPointerDown.current) return;
    const _isExpanded = handleExpand(); // using handleExpand() response because state isn't set yet
    console.log(object.name, 'isExpanded', _isExpanded);
    handleGotoState();
    isPointerDown.current = false;
  }, [handleExpand, handleGotoState, object.name]);

  useEffect(() => {
    handleOutline(isHovered);
  }, [handleOutline, isHovered]);

  useEffect(() => {
    console.log(object.name, 'isExpanded', isExpanded);
  }, [isExpanded, object.name]);

  return (
    <Html
      zIndexRange={isHovered ? [502, 501] : [500, 100]} // need component to update when changing zIndexRange
      style={{pointerEvents: 'none'}}
      position={[position.x, position.y, position.z]}
      calculatePosition={overrideCalculatePosition}
      name={'AnnotationDangerClick Html'}
    >
      <div className="annotation-container">

        <svg className={`annotation-circle dangerClick ${sizeClass} `}
          onPointerDown={onPointerDown}
          onPointerUp={onPointerUp}
          onPointerEnter={onPointerEnter}
          onPointerLeave={onPointerLeave}
        >
          <circle id="backCircle"/>
          <circle id="frontCircle"/>
          <text x="50%" y="50%" dy=".3em">{markerChar}</text>
        </svg>

        {markerTooltip && <div ref={annotationRef} className={`annotation dangerClick ${sizeClass} ${isExpanded ? 'visible' : ''}`}>
          <div className={`annotation-tooltip ${sizeClass}`} dangerouslySetInnerHTML={{__html: markerTooltip}}/>
          {/*TODO keep using dangerouslySetInnerHTML ? */}
        </div>}

      </div>
    </Html>
  );
};

export default AnnotationDangerClick;
