import create from 'zustand';
interface InsulationState {
  name: string,
  encoffrement: boolean,
  capotage: boolean,
  paroisAcoustiques: boolean,
  jointsEtJointures: boolean,
  lanieres: boolean,
  decouplage: boolean,
  plots: boolean,
  flexibles: boolean,
  illustation: string,
  carrousel: string
}

export class InsulationStates {
  static naked: InsulationState = {
    name: 'naked',
    encoffrement: false,
    capotage: false,
    paroisAcoustiques: false,
    jointsEtJointures: false,
    lanieres: false,
    decouplage: false,
    plots: false,
    flexibles: false,
    illustation: 'assets/img/4_cumul/vumetre/vumetre_nu.png',
    carrousel: 'assets/img/4_cumul/machine/00_machine_nue.jpg'
  }

  static lowFreq: InsulationState = {
    name: 'lowFreq',
    encoffrement: false,
    capotage: false,
    paroisAcoustiques: false,
    jointsEtJointures: false,
    lanieres: false,
    decouplage: true,
    plots: true,
    flexibles: true,
    illustation: 'assets/img/4_cumul/vumetre/vumetre_basse_freq.png',
    carrousel: 'assets/img/4_cumul/machine/02_machine_plots_et_flexibles.jpg'
  }

  static highFreqWeak: InsulationState = {
    name: 'highFreqWeak',
    encoffrement: true,
    capotage: true,
    paroisAcoustiques: true,
    jointsEtJointures: false,
    lanieres: false,
    decouplage: false,
    plots: false,
    flexibles: false,
    illustation: 'assets/img/4_cumul/vumetre/vumetre_haute_freq_faible.png',
    carrousel: 'assets/img/4_cumul/machine/01_machine_capotage.jpg'
  }

  static highFreqStrong: InsulationState = {
    name: 'highFreqStrong',
    encoffrement: true,
    capotage: true,
    paroisAcoustiques: true,
    jointsEtJointures: true,
    lanieres: true,
    decouplage: false,
    plots: false,
    flexibles: false,
    illustation: 'assets/img/4_cumul/vumetre/vumetre_haute_freq_fort.png',
    carrousel: 'assets/img/4_cumul/machine/01_machine_joints_tunnels_lanieres.jpg'
  }

  static full: InsulationState = {
    name: 'cumul',
    encoffrement: true,
    capotage: true,
    paroisAcoustiques: true,
    jointsEtJointures: true,
    lanieres: true,
    decouplage: true,
    plots: true,
    flexibles: true,
    illustation: 'assets/img/4_cumul/vumetre/vumetre_cumul.png',
    carrousel: 'assets/img/4_cumul/machine/03_machine_complete.jpg'
  }
}

type InsulationStoreProps = {
  currentInsulation?: InsulationState;
  setCurrentInsulation: (currentInsulation?: InsulationState) => void;
}

export const insulationStore = create<InsulationStoreProps>((set) => ({
  currentInsulation: undefined,
  setCurrentInsulation: (currentInsulation) => set({currentInsulation: currentInsulation})
}));
