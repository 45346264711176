import React, {useCallback, useEffect, useState} from 'react';
import DebugItem from 'components/Debug/DebugItem';
import {cameraStore, FSMStore, renderStore, skyRigStore} from 'webgl/stores';
import {sizeClassStore} from 'stores';
import {FSMStates} from 'webgl/types/FSMStates';
import {getTimeSeason} from 'webgl/helpers/solarAngles';

import 'components/Debug/DebugOverlay.scss';

const DebugOverlay: React.FC = () => {
  const sizeClass = sizeClassStore(state => state.sizeClass);
  const isIdle = renderStore(state => state.isIdle);
  const currentFSMState = FSMStore(state => state.currentFSMState);
  const currentFSMStateSiblings = FSMStore(state => state.currentFSMStateSiblings);
  const activeVirtualCamera = cameraStore(state => state.activeVirtualCamera);
  const time = skyRigStore(state => state.time);
  const season = skyRigStore(state => state.season);

  const takeScreenshot = () => {
    console.log('take screenshot');

    const a = document.createElement('a');
    a.href = document.getElementsByTagName('canvas')[0].toDataURL('image/png');
    a.setAttribute('download', 'webglCapture.png');
    if (window.confirm('Enregistrer une capture ?')) a.click();
  };

  return (
    <div className={`debug-overlay ${sizeClass}`}>
      <DebugItem text={'Current FSM State: '} value={currentFSMState}/>
      <DebugItem text={'Current FSM State Siblings: '} value={currentFSMStateSiblings?.join(', ')}/>
      <DebugItem text={'SizeClass: '} value={sizeClass}/>
      <DebugItem text={'IsIdle: '} value={isIdle.toString()}/>
      <DebugItem text={'Active Virtual Camera: '} value={`${activeVirtualCamera?.name} [${
        activeVirtualCamera?.position.toArray().map(comp => Math.round(comp * 100) / 100).join(', ')
      }]`}/>
      <button className="debug-item" style={{pointerEvents: 'auto'}} onPointerUp={takeScreenshot}>capture image</button>
      <hr/>
      <ListStates/>
    </div>
  );
};

export default DebugOverlay;

const ListStates: React.FC = () => {
  const setFSMState = FSMStore(state => state.setFSMState);
  const [states, setStates] = useState([]);
  const flatObjectDeep = useCallback((state): Array<string> => {
    return Object.keys(state).reduce((acc, cur) =>
        // @ts-ignore
        acc.concat(typeof state[cur] === 'object' ? flatObjectDeep(state[cur]) : state[cur])
      ,
      []);
  }, []);
  useEffect(() => {
    // @ts-ignore
    setStates(flatObjectDeep(FSMStates));
  }, [flatObjectDeep]);

  const gotoState = useCallback((e) => {
    setFSMState(e.target.getAttribute('data-state'));
  }, [setFSMState]);

  return (
    <>
      {states.map(state => <button key={state} data-state={state} className="debug-item" style={{pointerEvents: 'auto'}}
        onClick={gotoState}>{state}</button>)}
    </>
  );
};