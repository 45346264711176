import {useEffect} from 'react';
import {logHelper, tLogStyled} from 'utils/Logger';
import {LoopOnce, LoopRepeat} from 'three/src/constants';
import {FSMStore, renderStore} from 'webgl/stores';
import {useAnimations} from '@react-three/drei';
import {EquipmentEntity} from 'webgl/entities/EquipmentEntity';

export const useHandleAnimations = (equipmentEntity: EquipmentEntity): void => {
  const isIdle = renderStore(state => state.isIdle);
  const currentFSMState = FSMStore(state => state.currentFSMState);
  const {actions} = useAnimations(equipmentEntity.animations, equipmentEntity.scene);

  useEffect(() => {
    if (currentFSMState) {

      const actionShouldLoop = (state: string) => state.slice(-1) === '*';
      const sanitizeState = (state: string) => state.replace(/[\[|\(](.*?)[\]|\)]/g, '');

      const hasExactMatch = (validStates: string) => validStates
        .split('&') // split multiple valid states
        .map(validState => actionShouldLoop(validState) ? validState.slice(0, -1) : validState) // remove last char if * is added for looping
        .map(validState => sanitizeState(validState)) // remove [xxx] or (xxx) for multiple animation in the same state
        .some(validState => validState === currentFSMState || validState === 'allStates'); // check if currentState is a valid state

      const isValidRecursive = (validStates: string, currentFSMState: string): boolean => {
        if (!validStates || !currentFSMState) return false;

        if (hasExactMatch(validStates)) return true;

        else {
          const parent = currentFSMState.split('.').slice(0, -1); // remove last element
          if (parent.length > 0) return isValidRecursive(validStates, parent.join('.'));
        }
        return false;
      };


      Object.keys(actions)
        .filter(key => !!actions[key])
        .forEach(key => {
          if (isValidRecursive(key, currentFSMState)) {
            if (actionShouldLoop(key)) {
              actions[key]!.loop = LoopRepeat;
              actions[key]!.clampWhenFinished = false;
            } else {
              actions[key]!.loop = LoopOnce;
              actions[key]!.clampWhenFinished = true;
            }
            actions[key]!.play();
            // actions[key]!.timeScale = 0.25;
            tLogStyled(`[useHandleAnimations] Play action ${key}`, logHelper.start); // DEBUG
          } else {
            actions[key]?.stop();
            tLogStyled(`[useHandleAnimations] Stop action ${key}`, logHelper.stop); // DEBUG
          }
        });
    }
  }, [currentFSMState, actions]);

  useEffect(() => {
    Object.keys(actions).filter(key => !!actions[key]).forEach(key => {
      actions[key]!.timeScale = isIdle ? 0 : 1;
    });
  }, [actions, isIdle]);
};