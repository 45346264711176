import create from 'zustand';
import {Asset} from 'interfaces';

type UserAssetsState<T> = {
  userAssets: T[];
  setUserAssets: (value: T[]) => T[];
};

const userAssetStore = create<UserAssetsState<Asset>>((set) => ({
  userAssets: [],
  setUserAssets: (value) => {
    set({userAssets: value});
    return value;
  }
}));

export {userAssetStore};