import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Homepage from 'components/Main/Homepage';
import Navigation from 'components/Main/Navigation';
import SidePanel from 'components/Main/SidePanel/SidePanel';
import CarrouselModal from 'components/Main/SidePanel/CarrouselModal';
import WebGL from 'webgl/components/WebGL';
import AnnotationImageClosableDescription from 'webgl/components/Annotations/AnnotationImageClosableDescription';
import StepList from 'components/Main/StepList/StepList';

import './Main.scss';
import CumulModal from "./SidePanel/CumulModal";
import ButtonFixed from 'components/Shared/ButtonFixed';
import {FSMStates} from 'webgl/types/FSMStates';
import {pxToRem} from 'utils/HtmlClassUtils';

const Main: React.FC = () => {
  return (
    <div className={`main-container flex flex-center`}>
      <Routes>
        {/*<Route path={'/'} element={<Homepage/>}/>*/}
        <Route path="*" element={<Homepage/>}/>
      </Routes>

      <WebGL/>
      <StepList/>
      <SidePanel/>
      <CarrouselModal/>
      <CumulModal/>
      <Navigation/>
      {/*<AnnotationOrangeFixedDescriptions/>*/}
      <AnnotationImageClosableDescription/>
      {/*<AnnotationDangerAlwaysOpened_FIXED annotationId={'annotation_main_non-conformite'} position={new Vector2(100, 50)} forcedAngle={ForcedAngle.topRight} activeState={FSMStates.webgl.main}/>*/}
      <ButtonFixed caption={'En savoir plus'} validStates={[FSMStates.webgl.main]} style={{bottom: pxToRem(60), right: pxToRem(90)}}/>
    </div>
  );
};

export default Main;
