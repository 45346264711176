import React from "react";
import './CumulationSidePanel.scss'
import Checkbox, {CheckboxType} from "../../Shared/Checkbox";
import {InsulationStates, insulationStore} from "../../../stores/insulationStore";

const CumulationSidePanel: React.FC = () => {
  const {currentInsulation, setCurrentInsulation} = insulationStore(state => ({
    currentInsulation: state.currentInsulation,
    setCurrentInsulation: state.setCurrentInsulation,
  }));

  return <div className={'cumulation-side-panel'}>
    <div className={'checkboxes'}>
      <div className={'left-side'}>
        <Checkbox
          onClick={(b) => {
            if (currentInsulation?.name === InsulationStates.lowFreq.name) {
              setCurrentInsulation(InsulationStates.full);
              return;
            }

            setCurrentInsulation(currentInsulation?.name !== InsulationStates.highFreqWeak.name ? InsulationStates.highFreqWeak : InsulationStates.naked)
          }
          }
          checked={currentInsulation?.encoffrement}
          type={CheckboxType.MAIN}
          label={'encoffrement'}
        />
        <Checkbox
          onClick={(b) => {
            if (currentInsulation?.name === InsulationStates.lowFreq.name) {
              setCurrentInsulation(InsulationStates.full);
              return;
            }
            setCurrentInsulation(currentInsulation?.name !== InsulationStates.highFreqWeak.name ? InsulationStates.highFreqWeak : InsulationStates.naked)
          }}
          checked={currentInsulation?.capotage}
          type={CheckboxType.SECONDARY}
          label={'capotage'}
        />
        <Checkbox
          onClick={(b) => {
            if (currentInsulation?.name === InsulationStates.lowFreq.name) {
              setCurrentInsulation(InsulationStates.full);
              return;
            }
            setCurrentInsulation(currentInsulation?.name !== InsulationStates.highFreqWeak.name ? InsulationStates.highFreqWeak : InsulationStates.naked)
          }}
          checked={currentInsulation?.paroisAcoustiques}
          type={CheckboxType.SECONDARY}
          label={'parois acoustiques'}
        />
        <Checkbox
          onClick={(b) => {
            if (currentInsulation?.name === InsulationStates.lowFreq.name) {
              setCurrentInsulation(InsulationStates.full);
              return;
            }

            setCurrentInsulation(currentInsulation?.name !== InsulationStates.highFreqStrong.name ? InsulationStates.highFreqStrong : InsulationStates.highFreqWeak)
          }}
          checked={currentInsulation?.jointsEtJointures}
          type={CheckboxType.SECONDARY}
          label={'joints et jointures'}
        />
        <Checkbox
          onClick={(b) => {
            if (currentInsulation?.name === InsulationStates.lowFreq.name) {
              setCurrentInsulation(InsulationStates.full);
              return;
            }
            setCurrentInsulation(currentInsulation?.name !== InsulationStates.highFreqStrong.name ? InsulationStates.highFreqStrong : InsulationStates.highFreqWeak)
          }}
          checked={currentInsulation?.lanieres}
          type={CheckboxType.SECONDARY}
          label={'lanières'}
        />
      </div>
      <div className={'right-side'}>
        <Checkbox
          onClick={(b) => {
            if (currentInsulation?.name === InsulationStates.highFreqWeak.name || currentInsulation?.name === InsulationStates.highFreqStrong.name) {
              setCurrentInsulation(InsulationStates.full);
              return;
            }
            setCurrentInsulation(currentInsulation?.name !== InsulationStates.lowFreq.name ? InsulationStates.lowFreq : InsulationStates.naked)
          }}
          checked={currentInsulation?.decouplage}
          type={CheckboxType.MAIN}
          label={'découplage'}
        />
        <Checkbox
          onClick={(b) => {
            if (currentInsulation?.name === InsulationStates.highFreqWeak.name || currentInsulation?.name === InsulationStates.highFreqStrong.name) {
              setCurrentInsulation(InsulationStates.full);
              return;
            }
            setCurrentInsulation(currentInsulation?.name !== InsulationStates.lowFreq.name ? InsulationStates.lowFreq : InsulationStates.naked)
          }}
          checked={currentInsulation?.plots}
          type={CheckboxType.SECONDARY}
          label={'plots'}
        />
        <Checkbox
          onClick={(b) => {
            if (currentInsulation?.name === InsulationStates.highFreqWeak.name || currentInsulation?.name === InsulationStates.highFreqStrong.name) {
              setCurrentInsulation(InsulationStates.full);
              return;
            }
            setCurrentInsulation(currentInsulation?.name !== InsulationStates.lowFreq.name ? InsulationStates.lowFreq : InsulationStates.naked)
          }}
          checked={currentInsulation?.flexibles}
          type={CheckboxType.SECONDARY}
          label={'flexibles'}
        />
      </div>
    </div>
    <div className={'graph'}>
      <img src={`./${currentInsulation?.illustation || InsulationStates.naked.illustation}`} alt={'vu-metre'}/>
    </div>
  </div>
}
export default CumulationSidePanel;