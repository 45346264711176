import {AssetEntity} from 'webgl/entities/AssetEntity';
import {useEffect} from 'react';
import {Object3DTypedArray} from 'webgl/types/Object3DTypedArray';
import {configurationStore} from 'webgl/stores';
import {logHelper, tLogStyled} from 'utils/Logger';

/**
 * Get all SortedObjects from Line assets and regroup them in a unique named array
 * to push them into configurationStore.sortedObjects.
 */
export const useUpdateSortedObjects = (assetEntities?: AssetEntity[]) => {
  const setSortedObjects = configurationStore(state => state.setSortedObjects);
  useEffect(() => {
    const sortedObjects: Object3DTypedArray = {};
    if (assetEntities && assetEntities.length > 0) {
      // TODO REFACTOR BELOW (mainly copied from AssetEntity)
      const firstItemOf = (type: string) => !sortedObjects[type];
      const initializePropertyOf = (type: string) => sortedObjects[type] = [];
      assetEntities.forEach(eq => {
        for (let type in eq.sortedObjects) {
          if (eq.sortedObjects.hasOwnProperty(type)) {
            if (firstItemOf(type)) initializePropertyOf(type);
            sortedObjects[type].push(...eq.sortedObjects[type]);
          }
        }
      });
    }
    tLogStyled('Line sortedObjects', logHelper.debug, sortedObjects);
    setSortedObjects(sortedObjects);
  }, [assetEntities, setSortedObjects]);
};