import {useEffect, useState} from 'react';
import {Vector3} from 'three/src/Three';
import {ConfigurationDTO} from 'interfaces';
import {cameraStore, configurationStore, GLTFAssetStore} from 'webgl/stores';
import {EquipmentEntity} from 'webgl/entities/EquipmentEntity';
import {AssetEntity} from 'webgl/entities/AssetEntity';
import {Asset3D} from 'webgl/interfaces';
import {useUpdateLineStates} from 'webgl/hooks/configuration/useUpdateLineStates';
import {useUpdateVirtualCameras} from 'webgl/hooks/configuration/useUpdateVirtualCameras';
import {useUpdateSortedObjects} from 'webgl/hooks/configuration/useUpdateSortedObjects';
import {Object3DTypedArray} from 'webgl/types/Object3DTypedArray';

const getEquipmentEntities = (entityKeys: string[], assets: Asset3D[]): EquipmentEntity[] => {
  const _newEquipmentAssets: EquipmentEntity[] = [];
  entityKeys?.forEach(eqKey => {
    const equip = assets.find(asset => asset.key === eqKey);
    if (equip !== undefined) {
      const equipment = new EquipmentEntity(equip);
      _newEquipmentAssets.push(equipment);
    }
  });
  return _newEquipmentAssets;
};
const applyEquipmentOffsets = (entities: EquipmentEntity[] = []) => {
  const _currentOffset: Vector3 = new Vector3();
  entities.forEach(entity => {
    entity.setPivotOffset(_currentOffset);
    entity.scene.updateMatrix();
    _currentOffset.add(entity.nextPivotPosition);
    // TODO doesn't take into account initial position of equipment pivot...
  });
};

const getAssetEntity = (entityKey: string, assets: Asset3D[]): AssetEntity | undefined => {
  const asset = assets.find(asset => asset.key === entityKey);
  if (asset) {
    return new AssetEntity(asset);
  }
};

type GetConfigurationAssetsResponse = [
  equipmentAssets?: EquipmentEntity[],
];

export const useGetConfigurationEntities = (configuration?: ConfigurationDTO): GetConfigurationAssetsResponse => {
  const parsedAssets = GLTFAssetStore(state => state.parsedAssets);
  const setConfigurationReadiness = configurationStore(state => state.setConfiguration3dReadiness);
  const resetVirtualCameras = cameraStore(state => state.resetVirtualCameras);

  const [equipmentEntities, setEquipmentEntities] = useState<EquipmentEntity[]>();

  useEffect(() => {
    setConfigurationReadiness(false);
    resetVirtualCameras();

    if (configuration && configuration.key && parsedAssets.length > 0) {
      // Equipments
      const _newEquipmentEntities = getEquipmentEntities(configuration.equipmentKeys, parsedAssets);
      applyEquipmentOffsets(_newEquipmentEntities);
      setEquipmentEntities(_newEquipmentEntities);

      // setConfigurationReadiness(true);
    }
  }, [configuration, parsedAssets, resetVirtualCameras, setConfigurationReadiness]);

  // useUpdateLineStates(equipmentEntities);
  useUpdateSortedObjects(equipmentEntities);
  useUpdateVirtualCameras(equipmentEntities);

  return [equipmentEntities];
};
