import React, {CSSProperties, useCallback, useRef} from 'react';
import {sizeClassStore} from 'stores';
import './ButtonFixed.scss';
import {FSMStore} from 'webgl/stores';
import {FSMStates} from 'webgl/types/FSMStates';

type ButtonFixedProps = {
  caption?: string;
  validStates?: string[];
  style?: CSSProperties
}

const ButtonFixed: React.FC<ButtonFixedProps> = (props: ButtonFixedProps) => {
  const sizeClass = sizeClassStore(state => state.sizeClass);
  const currentFSMState = FSMStore(state => state.currentFSMState);
  const setFSMStates = FSMStore(state => state.setFSMState);

  const isPointerDown = useRef<boolean>(false);
  const onPointerDown = useCallback(() => isPointerDown.current = true, []);
  const onPointerUp = useCallback(() => {
    if (!isPointerDown.current) return;
    setFSMStates(FSMStates.webgl.main_sub.savoirPlus);
    isPointerDown.current = false;
  }, [setFSMStates]);

  if (!props.caption || !props.validStates?.some(validState => currentFSMState?.startsWith(validState)))
    return null;

  return (
    <div className="button-fixed-container" style={props.style}>
      <div className={`button-fixed blueHover ${sizeClass} visible`}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
      >
        <div className={`title ${sizeClass}`}>{props.caption}</div>
      </div>
    </div>
  );
};

export default ButtonFixed;
