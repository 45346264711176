import {logHelper, tLogStyled} from 'utils/Logger';
import {Object3D} from 'three/src/core/Object3D';
import {AnnotationData} from 'webgl/types/AnnotationData';

// CONST DATA
declare const annotationData: Array<AnnotationData>; // imported from /public/editableContent.js, so no need to be compiled

// TODO FAKE DATA
/*
const descriptions_FAKE: Array<AnnotationData> = [
  {key: 'annotation_main_non-conformite', marker: '!', description:'Les machines illustrées présentent volontairement des non-conformités à des fins pédagogiques'},

  {key: 'annotation_pt1', marker:'1',},
  {key: 'annotation_pt2', marker:'2', title: `C'est quoi une machine ?`},
  {key: 'annotation_pt3', marker:'3', title: `Adéquation machine et travail à réaliser`},
  {key: 'annotation_pt4', marker:'4', title: `Risques générés par les machines`},
  {key: 'annotation_pt5', marker:'5', title: `"CE" ne veut pas dire conformité, ni sécurité !`},
  {key: 'annotation_pt6', marker:'6', title: `Bibliographie`},
  {key: 'annotation_pt7', marker:'7', title: `Pour aller plus loin…`},

  {key: 'annotation_contact', title: `Contact`},
  {key: 'annotation_credits', title: `Crédits`},

  {key: 'annotation_pt2_a', marker:'a', description: `Ensemble de pièces ou d'organes liés entre eux`},
  {key: 'annotation_pt2_b', marker:'b', description: `dont l'un au moins est mobile`},
  {key: 'annotation_pt2_c', marker:'c', description: `équipé ou destiné à être équipé d'un système d'entraînement autre que la force humaine employée directement`},
  {key: 'annotation_pt2_d', marker:'d', description: `réunis en vue d'une application définie`},

  {key: 'annotation_pt4_elec', marker: '!', description: `<img src="./assets/img/pt4/risque_electrique.png"/>`},
  {key: 'annotation_pt4_meca', marker: '!', description: `<img src="./assets/img/pt4/risque_mecanique.png"/>`},
  {key: 'annotation_pt4_chute', marker: '!', description: `<img src="./assets/img/pt4/risque_chute.png"/>`},
  {key: 'annotation_pt4_chimique', marker: '!', description: `<img src="./assets/img/pt4/risque_chimique.png"/>`},
  {key: 'annotation_pt4_bruit', marker: '!', description: `<img src="./assets/img/pt4/risque_bruit.png"/>`},

  {key: 'annotation_pt5_zoom', marker: '!'},
  {key: 'annotation_pt5_protec', marker: '!', description: `Absence de protecteurs ou de dispositifs de protection rendant inaccessibles les éléments mobiles de travail du palettiseur`},
  {key: 'annotation_pt5_ecran', marker: '!', description: `Les informations, messages, identifications des boutons et organes de service doivent être dans la langue du pays d'utilisation`},
];
*/

export const getAnnotationDataFromId__JSON = async (annotationId: string): Promise<AnnotationData> => {
  const description =
    annotationData.find(annotation => annotationId === annotation.key) || {key: annotationId};

  tLogStyled('[getAnnotationData__JSON()] using json data : annotation description', logHelper.warning, description);

  return new Promise<AnnotationData>((resolve) => {
    setTimeout(() => resolve(description), 0);
  });
};

export const getAnnotationData__JSON = async (annotationObject: Object3D): Promise<AnnotationData> => {
  const description =
    annotationData.find(annotation => annotationObject.userData.tags?.annotationId === annotation.key) ||
    {key: annotationObject.userData.tags?.annotationId};

  tLogStyled('[getAnnotationData__JSON()] using json data : annotation description', logHelper.warning, description);

  return new Promise<AnnotationData>((resolve) => {
    setTimeout(() => resolve(description), 0);
  });
};

export const getAnnotationsData__JSON = async (annotationObjects: Object3D[]): Promise<AnnotationData[]> => {
  const descriptions = annotationData.filter(annotation =>
    annotationObjects.some(obj =>
      obj.userData.tags?.annotationId === annotation.key));

  tLogStyled('[getAnnotationsData__JSON()] using json data : fixed annotation descriptions', logHelper.warning, descriptions);

  return new Promise<AnnotationData[]>((resolve) => {
    setTimeout(() => resolve(descriptions), 0);
  });
};

// export const getAnnotationData__FAKE = async (annotationObject: Object3D): Promise<AnnotationData> => {
//   const description =
//     descriptions_FAKE.find(description => annotationObject.userData.tags?.annotationId === description.key) ||
//     {key: annotationObject.userData.tags?.annotationId};
//
//   tLogStyled('[getAnnotationDescriptions__FAKE()] using fake data : annotation description', logHelper.warning, description);
//
//   return new Promise<AnnotationData>((resolve) => {
//     setTimeout(() => resolve(description), 0);
//   });
// };
//
// export const getAnnotationsData__FAKE = async (annotationObjects: Object3D[]): Promise<AnnotationData[]> => {
//   const descriptions = descriptions_FAKE.filter(description =>
//     annotationObjects.some(obj =>
//       obj.userData.tags?.annotationId === description.key));
//
//   tLogStyled('[getAnnotationDescriptions__FAKE()] using fake data : fixed annotation descriptions', logHelper.warning, descriptions);
//
//   return new Promise<AnnotationData[]>((resolve) => {
//     setTimeout(() => resolve(descriptions), 0);
//   });
// };