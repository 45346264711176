import React, {useEffect, useState} from 'react';
import {FSMStore} from 'webgl/stores';
import {stepListStore} from 'stores';
import {getSidePanelContent__JSON} from 'services/getSidePanelContent';
import {logHelper, tLogStyled} from 'utils/Logger';

import './StepList.scss';
import StepListItem from 'components/Main/StepList/StepListItem';

const StepList: React.FC = () => {
  const currentFSMState = FSMStore(state => state.currentFSMState);

  const steps = stepListStore(state => state.steps);
  const addStep = stepListStore(state => state.addStep);
  const setCurrentStep = stepListStore(state => state.setCurrentStep);

  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    getSidePanelContent__JSON(currentFSMState)
      .then(res => {
        tLogStyled('[StepList] received data ', logHelper.debug, res.stepListItem);
        // setStepList(res.stepListItem);
        if (res.stepListItem !== undefined) {
          addStep(res.stepListItem);
          setCurrentStep(res.stepListItem);
          setVisible(true);
        } else {
          setVisible(false); // hide if undefined
        }
      }).catch(err => {
      setVisible(false); // hide if undefined
    });

  }, [addStep, currentFSMState, setCurrentStep]);

  // We display StepList only if stepListItem is defined in editableContent.js, but we display data stored in stepListStore

  if (!visible) return null;

  return (
    <div className={`step-list`}>
      {/*<SidePanelTitle marker={marker} title={title}/>*/}
      <div className="vertical-line"/>
      {steps
        .sort((a, b) => a.order! - b.order!)
        .map((stepListItem, i) =>
          <StepListItem item={stepListItem} highlight={currentFSMState === stepListItem.state}/>
        )}
    </div>
  );
};

export default StepList;