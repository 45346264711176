import {Season} from 'webgl/types/season';
import {MathUtils} from 'three';

// const getHours = (time: number) => Math.floor(time / 100) * 100;
// const getMinutes = (time: number) => time % 100;

// export const map100to60 = (time: number) => {
//   const hours = getHours(time);
//   const minutes = getMinutes(time);
//   return hours + minutes / 100 * 60; // 25 => 15min
// };

// export const interpolateSolarAngles = (time: number, season: Season): [number, number] => {
//   const angles = season === Season.WINTER ? decemberAngles : juneAngles;
//   const hours = getHours(time); // 14
//   const minutes = getMinutes(time); // 57.6
//
//   const quarterBefore = 25 * Math.floor(minutes / 25); // 50
//   const timeBefore = hours + quarterBefore; // 1450
//   const quarterAfter = 25 * Math.ceil(minutes / 25); // 75
//   const timeAfter = hours + quarterAfter; // 1475
//   const percentage = (minutes / 25) % 1; // 0.3
//
//   const anglesBefore = angles[map100to60(timeBefore)];
//   const anglesAfter = angles[map100to60(timeAfter)];
//
//   const interpolatedElevation = MathUtils.lerp(anglesBefore[0], anglesAfter[0], percentage);
//   const interpolatedAzimuth = MathUtils.lerp(anglesBefore[1], anglesAfter[1], percentage);
//
//   return [interpolatedElevation, interpolatedAzimuth];
// };

export const getTimeSeason = (dayProgress: number, season: Season): string => {
  const angles = season === Season.WINTER ? decemberAngles : juneAngles;
  const anglesArray = Object.values(angles);
  const anglesCount = anglesArray.length - 1;
  const timeArray = Object.keys(angles);

  const indexBefore = MathUtils.clamp(Math.floor(dayProgress * anglesCount), 0, anglesCount);
  // const indexAfter = MathUtils.clamp(Math.ceil(dayProgress * anglesCount), 0, anglesCount);
  // const percentage = (dayProgress * anglesCount) % 1;
  const time = timeArray[indexBefore];
  // const timeBefore = Number.parseInt(timeArray[indexBefore]);
  // const timeAfter = Number.parseInt(timeArray[indexAfter]);
  // const interpolatedTime = MathUtils.lerp(timeBefore, timeAfter, percentage);

  return `${time} (${dayProgress}) [${season === Season.WINTER ? 'hiver' : 'été'} (${season})]`;
};

export const interpolateSolarAngles01 = (dayProgress: number, season: Season): [number, number] => {
  const angles = season === Season.WINTER ? decemberAngles : juneAngles;
  const anglesArray = Object.values(angles);
  const anglesCount = anglesArray.length - 1;

  const indexBefore = MathUtils.clamp(Math.floor(dayProgress * anglesCount), 0, anglesCount);
  const indexAfter = MathUtils.clamp(Math.ceil(dayProgress * anglesCount), 0, anglesCount);
  const percentage = (dayProgress * anglesCount) % 1;
  const anglesBefore = anglesArray[indexBefore];
  const anglesAfter = anglesArray[indexAfter];

  const interpolatedElevation = MathUtils.lerp(anglesBefore[0], anglesAfter[0], percentage);
  const interpolatedAzimuth = MathUtils.lerp(anglesBefore[1], anglesAfter[1], percentage);

  return [interpolatedElevation, interpolatedAzimuth];
};

// export const getSolarAngles = (time: number, season: Season): [number, number] => {
//   const [elevation, azimuth] = interpolateSolarAngles(time, season);
//   return [elevation, azimuth];
// };

export const getSolarAngles01 = (time: number, season: Season): [number, number] => {
  const [elevation, azimuth] = interpolateSolarAngles01(time, season);
  return [elevation, azimuth];
};

// https://keisan.casio.com/exec/system/1224682277
// [hhmm, elevation, azimuth]
export const juneAngles: { [k: number]: [number, number] } = {
  // 0: [-16.30, 333.89],
  // 15: [-17.37, 337.31],
  // 30: [-18.30, 340.78],
  // 45: [-19.08, 344.32],
  // 100: [-19.70, 347.91],
  // 115: [-20.16, 351.53],
  // 130: [-20.46, 355.18],
  // 145: [-20.60, 358.85],
  // 200: [-20.57, 2.53],
  // 215: [-20.37, 6.19],
  // 230: [-20.01, 9.83],
  // 245: [-19.48, 13.45],
  // 300: [-18.80, 17.01],
  // 315: [-17.97, 20.53],
  // 330: [-16.98, 23.98],
  // 345: [-15.86, 27.37],
  // 400: [-14.60, 30.68],
  // 415: [-13.21, 33.92],
  // 430: [-11.71, 37.09],
  // 445: [-10.09, 40.17],
  // 500: [-8.36, 43.18],
  // 515: [-6.53, 46.12],
  // 530: [-4.53, 48.99],
  // 545: [-2.05, 51.79],
  600: [0.12, 54.53],
  615: [2.03, 57.21],
  630: [4.14, 59.84],
  645: [6.36, 62.42],
  700: [8.66, 64.97],
  715: [11.03, 67.49],
  730: [13.44, 69.98],
  745: [15.90, 72.46],
  800: [18.39, 74.93],
  815: [20.91, 77.41],
  830: [23.46, 79.90],
  845: [26.03, 82.41],
  900: [28.61, 84.96],
  915: [31.21, 87.56],
  930: [33.81, 90.23],
  945: [36.41, 92.97],
  1000: [39.01, 95.83],
  1015: [41.59, 98.81],
  1030: [44.15, 101.94],
  1045: [46.68, 105.26],
  1100: [49.17, 108.80],
  1115: [51.60, 112.61],
  1130: [53.97, 116.74],
  1145: [56.24, 121.24],
  1200: [58.41, 126.19],
  1215: [60.44, 131.66],
  1230: [62.29, 137.71],
  1245: [63.93, 144.40],
  1300: [65.30, 151.76],
  1315: [66.37, 159.75],
  1330: [67.09, 168.27],
  1345: [67.42, 177.14], // zenith
  1400: [67.35, 186.09],
  1415: [66.88, 194.85],
  1430: [66.03, 203.20],
  1445: [64.84, 210.97],
  1500: [63.36, 218.09],
  1515: [61.64, 224.54],
  1530: [59.72, 230.37],
  1545: [57.64, 235.65],
  1600: [55.43, 240.43],
  1615: [53.12, 244.79],
  1630: [50.73, 248.80],
  1645: [48.27, 252.51],
  1700: [45.77, 255.96],
  1715: [43.23, 259.21],
  1730: [40.66, 262.29],
  1745: [38.07, 265.22],
  1800: [35.47, 268.03],
  1815: [32.87, 270.74],
  1830: [30.27, 273.38],
  1845: [27.68, 275.96],
  1900: [25.10, 278.50],
  1915: [22.54, 281.00],
  1930: [20.00, 283.49],
  1945: [17.48, 285.96],
  2000: [15.00, 288.43],
  2015: [12.56, 290.92],
  2030: [10.17, 293.42],
  2045: [7.83, 295.95],
  2100: [5.55, 298.51],
  2115: [3.36, 301.11],
  2130: [1.31, 303.75],
  2140: [0, 305], //added manually
  // 2145: [-0.55, 306.46],
  // 2200: [-3.02, 309.22],
  // 2215: [-5.28, 312.04],
  // 2230: [-7.21, 314.93],
  // 2245: [-9.00, 317.89],
  // 2300: [-10.68, 320.93],
  // 2315: [-12.26, 324.04],
  // 2330: [-13.73, 327.24],
  // 2345: [-15.07, 330.50],
  // 2400: [-16.28, 333.84]
};
export const decemberAngles: { [k: number]: [number, number] } = {
  // 0: [-65.62, 333.86],
  // 15: [-66.60, 342.00],
  // 30: [-67.22, 350.63],
  // 45: [-67.44, 359.54],
  // 100: [-67.26, 8.47],
  // 115: [-66.68, 17.14],
  // 130: [-65.74, 25.34],
  // 145: [-64.47, 32.94],
  // 200: [-62.92, 39.88],
  // 215: [-61.14, 46.16],
  // 230: [-59.18, 51.83],
  // 245: [-57.06, 56.97],
  // 300: [-54.82, 61.63],
  // 315: [-52.49, 65.89],
  // 330: [-50.08, 69.81],
  // 345: [-47.61, 73.45],
  // 400: [-45.09, 76.85],
  // 415: [-42.54, 80.04],
  // 430: [-39.97, 83.08],
  // 445: [-37.38, 85.97],
  // 500: [-34.78, 88.76],
  // 515: [-32.18, 91.45],
  // 530: [-29.58, 94.07],
  // 545: [-26.99, 96.64],
  // 600: [-24.42, 99.16],
  // 615: [-21.86, 101.66],
  // 630: [-19.33, 104.14],
  // 645: [-16.82, 106.61],
  // 700: [-14.35, 109.09],
  // 715: [-11.92, 111.57],
  // 730: [-9.54, 114.08],
  // 745: [-7.19, 116.62],
  // 800: [-4.83, 119.19],
  // 815: [-2.10, 121.80],
  830: [0.17, 124.46],
  845: [2.04, 127.17],
  900: [3.97, 129.95],
  915: [5.87, 132.79],
  930: [7.71, 135.70],
  945: [9.46, 138.68],
  1000: [11.11, 141.74],
  1015: [12.66, 144.87],
  1030: [14.09, 148.08],
  1045: [15.40, 151.37],
  1100: [16.57, 154.73],
  1115: [17.61, 158.16],
  1130: [18.50, 161.65],
  1145: [19.24, 165.20],
  1200: [19.83, 168.79],
  1215: [20.25, 172.42],
  1230: [20.51, 176.08],
  1245: [20.61, 179.75], // zenith
  1300: [20.54, 183.42],
  1315: [20.30, 187.08],
  1330: [19.90, 190.72],
  1345: [19.33, 194.32],
  1400: [18.61, 197.88],
  1415: [17.74, 201.38],
  1430: [16.72, 204.81],
  1445: [15.56, 208.18],
  1500: [14.27, 211.48],
  1515: [12.86, 214.70],
  1530: [11.33, 217.84],
  1545: [9.69, 220.91],
  1600: [7.95, 223.90],
  1615: [6.13, 226.82],
  1630: [4.23, 229.67],
  1645: [2.30, 232.45],
  1700: [0.42, 235.18],
  // 1715: [-1.71, 237.85],
  // 1730: [-4.49, 240.46],
  // 1745: [-6.88, 243.04],
  // 1800: [-9.22, 245.58],
  // 1815: [-11.60, 248.09],
  // 1830: [-14.02, 250.58],
  // 1845: [-16.49, 253.05],
  // 1900: [-18.99, 255.53],
  // 1915: [-21.52, 258.00],
  // 1930: [-24.07, 260.50],
  // 1945: [-26.65, 263.02],
  // 2000: [-29.23, 265.58],
  // 2015: [-31.83, 268.19],
  // 2030: [-34.43, 270.87],
  // 2045: [-37.03, 273.65],
  // 2100: [-39.62, 276.52],
  // 2115: [-42.20, 279.54],
  // 2130: [-44.75, 282.71],
  // 2145: [-47.27, 286.08],
  // 2200: [-49.75, 289.68],
  // 2215: [-52.17, 293.56],
  // 2230: [-54.52, 297.77],
  // 2245: [-56.77, 302.37],
  // 2300: [-58.90, 307.44],
  // 2315: [-60.89, 313.04],
  // 2330: [-62.70, 319.24],
  // 2345: [-64.28, 326.08],
  // 2400: [-65.59, 333.59],
};