import create from 'zustand';

export type CarrouselItem = { url: string, caption?: string };

type CarrouselStoreProps = {
  carrouselItems?: CarrouselItem[];
  setCarrouselItems: (items?: CarrouselItem[]) => void;

  carrouselIndex: number;
  setCarrouselIndex: (value: number) => void;

  displayCarrouselModal: boolean;
  setDisplayCarrouselModal: (value: boolean, index?: number) => void;
}

export const carrouselStore = create<CarrouselStoreProps>((set) => ({
  carrouselItems: undefined,
  setCarrouselItems: (items) => set({carrouselItems: items}),

  carrouselIndex: 0,
  setCarrouselIndex: (value) => set({carrouselIndex: value}),

  displayCarrouselModal: false,
  setDisplayCarrouselModal: (value, index = 0) => set({displayCarrouselModal: value, carrouselIndex: index}),
}));
