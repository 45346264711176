import create from 'zustand';
import {ConfigurationDTO} from 'interfaces';
import {Object3DTypedArray} from 'webgl/types/Object3DTypedArray';

// export type Object3DTypedArray = { [key: string]: Object3D[] };

export type ConfigurationStoreState = {
  configuration?: ConfigurationDTO;
  setConfiguration: (value: ConfigurationDTO) => ConfigurationDTO;

  isConfiguration3dReady: boolean;
  setConfiguration3dReadiness: (value: boolean) => void;

  sortedObjects: Object3DTypedArray;
  setSortedObjects: (sortedObjects: Object3DTypedArray) => void;
};

const configurationStore = create<ConfigurationStoreState>((set) => ({
  configuration: undefined,
  setConfiguration: (config) => {
    set({
      configuration: config,
      isConfiguration3dReady: false
    });
    return config;
  },
  isConfiguration3dReady: false,
  setConfiguration3dReadiness: (value) => set({isConfiguration3dReady: value}),

  sortedObjects: {},
  setSortedObjects: (sortedObjects) => set({sortedObjects: sortedObjects})
}));

export {configurationStore};