export const arrayEquals = (a: unknown, b: unknown): boolean => {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index]);
};

export const arrayItemEquals = (a: unknown, b: unknown): boolean => {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every(value => b.includes(value)) && // b contains all a values
    b.every(value => a.includes(value)); // but also a contains all b values
};

export const flatDeep = (array: any[]): any[] => {
  return array.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val) : val), []);
}
