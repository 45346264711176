import React from 'react';

import './Spinner.scss';

type SpinnerProps = {
  percentage?: number;
};

const Spinner: React.FC<SpinnerProps> = ({percentage}) => {

  return (
    <svg className="spinner" viewBox="0 0 50 50">
      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"/>
      <text className="text"  x="50%" y="50%" dy=".3em">{percentage}</text>
    </svg>
  );
};

export default Spinner;