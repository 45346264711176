import React, {useEffect, useRef} from 'react';
import {addClass, removeClass} from 'utils/HtmlClassUtils';

import 'components/Debug/DebugOverlay.scss';

const DebugValue: React.FC<{value?: string}> = ({value}) => {
  const spanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    let resetFlashTimeout: ReturnType<typeof setTimeout>;
    if (spanRef.current) {
      removeClass(spanRef, 'flash');
      resetFlashTimeout = setTimeout(() => addClass(spanRef, 'flash'), 50);
      // using 50ms to let the dom realize the class has been removed, even 10ms causes issues...
    }
    return () => clearTimeout(resetFlashTimeout);
  }, [value]);

  return <span ref={spanRef} className="debug-item-value">{value}</span>;
};

export default DebugValue;