import React from 'react';

import './Carrousel.scss';
import {InsulationStates, insulationStore} from "../../../stores/insulationStore";
import {FSMStore} from "../../../webgl/stores";
import {FSMStates} from "../../../webgl/types/FSMStates";

const CumulModal: React.FC = () => {
  const {currentInsulation} = insulationStore(state => ({
    currentInsulation: state.currentInsulation,
  }));
  const currentFSMState = FSMStore(state => state.currentFSMState);


  if (currentFSMState !== FSMStates.webgl.cumul) return null;

  return (
    <div className={`cumul-modal`}>
      <div className={`cumul-item modal opened`}>
        {/*{displayYoutubePlayer ?*/}
        {/*  <YoutubeVideo item={items[currentIndex]}/> :*/}
        {/*  <img src={items[currentIndex].url} alt={items[currentIndex].caption}/>*/}
        {/*}*/}

        <img src={currentInsulation ? currentInsulation.carrousel : InsulationStates.naked.carrousel}
             alt={currentInsulation ? currentInsulation.carrousel : InsulationStates.naked.carrousel}/>
      </div>
    </div>
  );
};

export default CumulModal;