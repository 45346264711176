export const AnnotationStyles = {
  defaultExpandable: 'defaultExpandable',
  blueHover: 'blueHover',
  orangeHover: 'orangeHover',
  orangeHoverClosable: 'orangeHoverClosable', // encoffrement
  dangerAlwaysOpened: 'dangerAlwaysOpened',
  dangerClick: 'dangerClick',
  blueFixed: 'blueFixed',
  orangeFixed: 'orangeFixed',
  boxThumbnail: 'boxThumbnail', // ensoleillement
  imageClosable: 'imageClosable' // encoffrement
};
