import create from 'zustand';
import produce from 'immer';
import {Vector3} from 'three';

export type ControlsProps = {
  enabled: boolean;
  enableDamping: boolean;
  enablePan: boolean;
  enableRotate: boolean;
  enableZoom: boolean;
  autoRotate: boolean;
  minAzimuthAngle: number;
  maxAzimuthAngle: number;
  minPolarAngle: number;
  maxPolarAngle: number;
  autoRotateSpeed: number;
  groundThreshold: number | null;
};

export type ControlsStoreProps = {
  config: ControlsProps;
  setConfig: (newConfig: Partial<ControlsProps>) => void;

  getTarget: () => Vector3;
  setTarget: (pos: Vector3) => void;
  saveState: () => void;
  update: () => void;
  reset: () => void;
};

const initialState = {
  enabled: false,
  enableDamping: false,
  enablePan: false,
  enableRotate: false,
  enableZoom: false,
  autoRotate: false,
  minAzimuthAngle: -Infinity,
  maxAzimuthAngle: Infinity,
  minPolarAngle: 0,
  maxPolarAngle: Math.PI,
  autoRotateSpeed: 1,
  groundThreshold: null
};

export const controlsStore = create<ControlsStoreProps>((set, get) => ({
  config: initialState,
  setConfig: (newConfig) => {
    const config = produce(get().config, draft => {
      return {...draft, ...newConfig};
    });

    set({config: config});
  },

  getTarget: () => new Vector3(),
  setTarget: (pos) => void 0,
  saveState: () => void 0,
  update: () => void 0,
  reset: () => void 0,
}));