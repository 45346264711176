import {useEffect, useState} from 'react';
import {FSMStates} from 'webgl/types/FSMStates';
import {FSMStore} from 'webgl/stores';

export const useGetNavigationData = (): [boolean, boolean, string | undefined, string | undefined, string | undefined] => {
  const currentFSMState = FSMStore(state => state.currentFSMState);
  const fsmStateStack = FSMStore(state => state.fsmStateStack);
  const isSubStateOf = FSMStore(state => state.isSubStateOf);
  const currentFSMStateSiblings = FSMStore(state => state.currentFSMStateSiblings);
  const overrideNextState = FSMStore(state => state.overrideNextState);


  const [displaySubstateArrows, setDisplaySubstateArrows] = useState<boolean>(false);
  const [displayNavigation, setDisplayNavigation] = useState<boolean>(false);
  const [prevState, setPrevState] = useState<string | undefined>();
  const [nextState, setNextState] = useState<string | undefined>();
  const [parentState, setParentState] = useState<string | undefined>();

  useEffect(() => {
    if (currentFSMState !== undefined) {
      const getPreviousState = (loop: boolean = false) => {
        const previousIndex = currentFSMStateSiblings.indexOf(currentFSMState) - 1;
        const shouldLoop = loop && currentFSMStateSiblings.length > 1;
        if (previousIndex < 0) return shouldLoop ? currentFSMStateSiblings[currentFSMStateSiblings.length - 1] : undefined; // return undefined; // undefined will hide arrow, return currentFSMStateSiblings[currentFSMStateSiblings.length - 1] will loop to last item
        else return currentFSMStateSiblings[previousIndex];
      };
      const getNextState = (loop: boolean = false) => {
        if (overrideNextState) {
          console.log('overriding next state', overrideNextState);
          return overrideNextState;
        }
        let nextIndex = currentFSMStateSiblings.indexOf(currentFSMState) + 1;
        const shouldLoop = loop && currentFSMStateSiblings.length > 1;
        if (nextIndex >= currentFSMStateSiblings.length) return shouldLoop ? currentFSMStateSiblings[0] : undefined; // return undefined; // undefined will hide arrow, return currentFSMStateSiblings[0] will loop to first item
        else return currentFSMStateSiblings[nextIndex];
      };
      // const getParentState = () => currentFSMState.split('_')[0]; // TODO HACK TO RETRIEVE PARENT STATE USING '_' CHAR...
      const getParentState = () => currentFSMState.substring(0, currentFSMState.lastIndexOf('_')); // TODO HACK TO RETRIEVE PARENT STATE USING '_' CHAR...

      if (isSubStateOf(FSMStates.webgl.pourquoi) ||
        isSubStateOf(FSMStates.webgl.evaluer) ||
        isSubStateOf(FSMStates.webgl.isoler) ||
        isSubStateOf(FSMStates.webgl.decoupler) ||
        isSubStateOf(FSMStates.webgl.cumul)) {
        const splitState = currentFSMState.split('.');
        if (isSubStateOf(FSMStates.webgl.pourquoi)) {
          setDisplaySubstateArrows(true);
          setDisplayNavigation(false);
          setParentState(fsmStateStack[fsmStateStack.length - 2]); // GOTO PREVIOUS STATE
        } else if (splitState.length <= 3) { // HACK TO CHANGE ARROWS
          setDisplaySubstateArrows(false);
          setDisplayNavigation(true);
          setPrevState(getPreviousState(false));
          setNextState(getNextState(false));
        } else {
          setDisplaySubstateArrows(true);
          setDisplayNavigation(true);
          setParentState(getParentState());
          setPrevState(getPreviousState(true));
          setNextState(getNextState(true));
        }

      } else {
        // disable prev/next buttons if not within `webgl.points` states
        setPrevState(undefined);
        setNextState(undefined);
        setDisplayNavigation(false /*currentFSMState === FSMStates.webgl.contact || *//*currentFSMState === FSMStates.webgl.credits*/);
        setDisplaySubstateArrows(false);
      }

    } else {
      setDisplayNavigation(false); // hide Navigation
      setDisplaySubstateArrows(false);
    }
  }, [currentFSMState, currentFSMStateSiblings, isSubStateOf, overrideNextState]);

  return [displaySubstateArrows, displayNavigation, prevState, nextState, parentState];
};