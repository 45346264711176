import React, {useCallback, useEffect} from 'react';
import {cameraStore, configurationStore} from 'webgl/stores';
import {useFrame, useThree} from '@react-three/fiber';
import {DoubleSide, Object3D, Vector3} from 'three';

type BillboardsProps = {
  lockX?: boolean;
  lockY?: boolean;
  lockZ?: boolean;
}

const Billboards: React.FC<BillboardsProps> = ({lockX = false, lockY = false, lockZ = false}) => {
  const bbObjects = configurationStore(state => state.sortedObjects['billboard']);
  const cameraBrain = cameraStore(state => state.cameraBrain);

  const {gl} = useThree();
  const processBillboardMaterials = useCallback((objects: Array<Object3D>) => {
    if (objects) {
      objects.forEach(billboard => {
        // @ts-ignore
        const material = billboard.material;
        if (material) {
          material.toneMapped = false;
          // material.side = DoubleSide;
          if (material.map)
            material.map.anisotropy = Math.min(4, gl.capabilities.getMaxAnisotropy());
        }
      });
    }
  }, [gl.capabilities]);
  useEffect(() => {
    processBillboardMaterials(bbObjects);
  }, [bbObjects, processBillboardMaterials]);

  const updateBillboardsTransform = () => {
    if (bbObjects.length < 1) return;

    // rotate billboard to follow camera
    bbObjects.forEach(billboard => rotateBillboard(billboard));
  };
  const rotateBillboard = (mesh: Object3D) => {
    if (!mesh || !cameraBrain) return;

    const cameraPos = cameraBrain.position;
    const target = new Vector3(
      !lockX ? cameraPos.x : mesh.position.x,
      !lockY ? cameraPos.y : mesh.position.y,
      !lockZ ? cameraPos.z : mesh.position.z
    );

    mesh.lookAt(target);

    mesh.rotateX(Math.PI / 2);

    // for locking certain axes see https://github.com/pmndrs/drei/blob/master/src/Billboard.tsx
  };

  useFrame(() => {
    // console.log(cameraBrain?.position);
    updateBillboardsTransform();
  });

  return null;
};

export default Billboards;