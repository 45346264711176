import React from "react";
import './Checkbox.scss';

export enum CheckboxType {
  MAIN,
  SECONDARY
}

export interface CheckboxProps {
  label: string,
  type: CheckboxType,
  checked?: boolean,
  onClick?: (b: boolean) => void
}

const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
  return <div
    className={`checkbox-container ${props.type === CheckboxType.MAIN ? 'main' : 'secondary'}`}
    onClick={() => props.onClick?.(!props.checked)}
  >
    <div className={'checkbox'} style={{
      backgroundImage: props.checked ? "url('./assets/img/tick.png')" : ''
    }}/>
    <span>{props.label}</span>
  </div>
}

export default Checkbox;