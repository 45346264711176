import create from 'zustand';
import {StepListItem} from 'webgl/types/StepListItem';

export type StepListStoreProps = {
  steps: StepListItem[]
  addStep: (newStep?: StepListItem) => void
  currentStep?: StepListItem
  setCurrentStep: (step?: StepListItem) => void
}

export const stepListStore = create<StepListStoreProps>((set, get) => ({
  steps: [], // initially set because we set 'benefices' state at first
  addStep: (newStep) => {
    if (newStep) {
      const steps = get().steps;
      // check if already added
      const stepExists = steps.findIndex(step => step.title === newStep.title && step.marker === newStep.marker && step.state === newStep.state) !== -1;
      if (!stepExists) {
        set({steps: [...steps, newStep]});
        console.log('Adding steps', get().steps);
      }
    }
  },
  currentStep: undefined,
  setCurrentStep: (step) => set({currentStep: step})
}));