import {AnimationClip, Group, Vector3} from 'three/src/Three';
import {Asset3D} from 'webgl/interfaces';
import {Object3D} from 'three/src/core/Object3D';
import {Object3DTypedArray} from 'webgl/types/Object3DTypedArray';

export class AssetEntity {
  //#region Fields

  public key: string;
  public scene: Group; // from GLTFLoader
  public sortedObjects: Object3DTypedArray;
  public animations: AnimationClip[]; // from GLTFLoader
  // public cameras: Camera[]; // from GLTFLoader

  protected _pivotObject?: Object3D; // Object3D whose position represents this asset pivot
  protected _pivotOffset: Vector3 = new Vector3();
  protected _pivotOffsetWorld: Vector3 = new Vector3();

  //#endregion

  //#region Properties

  get pivotOffset() {
    return this._pivotOffset;
  }

  get pivotOffsetWorld() {
    return this._pivotOffsetWorld;
  }

  //#endregion

  //#region Constructor
  constructor(asset: Asset3D) { // TODO correct parameter type?
    this.key = asset.key;
    this.scene = asset.scene.clone(true);
    this.sortedObjects = this.sortObjectsByType(this.scene);
    this.animations = asset.animations;
    // this.cameras = asset.cameras;

    this._pivotObject = this.getObjectByType('asset_pivot') || this.getObjectByType('equipment_pivot'); // TODO remove 'equipment_pivot' (only for debug compatibility)
  }

  //#endregion

  //#region Public Methods

  public setPivotOffset = (pos: Vector3) => {
    this._pivotOffset.copy(pos);
    const position = this._pivotObject?.position || new Vector3();
    this._pivotOffsetWorld = new Vector3().sub(position).add(this._pivotOffset);
  };

  public getObjectByType = (type: string): Object3D | undefined => {
    return this.getObjectsByType(type, 1)[0] || undefined;
  };

  public getObjectsByType = (type: string, limit: number = Infinity): Object3D[] => {
    let foundObjects: Object3D[] = this.sortedObjects[type] || [];
    return foundObjects.slice(0, limit);
  };

  //#endregion

  //#region Private Methods

  /**
   * Sort GLTF Scene children by their type.
   * <p>The `type` is read from `.userData.tags` of the child `Object3D`</p>
   */
  protected sortObjectsByType = (scene: Object3D): Object3DTypedArray => {
    const result: Object3DTypedArray = {}; // :Object3DTypedArray
    const getTypeOf = (obj: Object3D) => obj.userData.tags?.type;
    const firstItemOf = (type: string) => !result[type];
    const initializePropertyOf = (type: string) => result[type] = [];

    scene.traverse(obj => {
      const types = getTypeOf(obj);
      if (types) {
        const splitTypes = types.split('&');
        splitTypes.forEach((type: string) => {
          if (firstItemOf(type)) initializePropertyOf(type);
          result[type].push(obj);
        });
      }
    });

    return result;
  };

  //#endregion
}
