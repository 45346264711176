import React from 'react';
import {PerspectiveCamera} from 'three';
import {EquipmentEntity} from 'webgl/entities/EquipmentEntity';
import Equipment from 'webgl/components/Configuration/Equipment';

type LineState = {
  equipments: EquipmentEntity[];
  defaultCamera?: PerspectiveCamera
};

const Line: React.FC<LineState> = ({equipments}) => {
  if (!equipments) return null;
  return (
    <group name={'Center Group'}>
      {equipments.map(eq =>
        <Equipment key={eq.key} equipmentEntity={eq}/>
      )}
    </group>
  );
};

export default Line;
