import {cameraStore, controlsStore, FSMStore} from 'webgl/stores';
import {useCallback, useEffect, useRef} from 'react';
import {useFrame, useThree} from '@react-three/fiber';
import {easeOutCubic} from 'utils/Easings';
import {MathUtils, PerspectiveCamera, Quaternion, Vector3} from 'three';
import {DefaultValues} from 'webgl/types/DefaultValues';
import {logHelper, tLogStyled} from 'utils/Logger';
import {getHorizontalFov, getVerticalFov} from 'webgl/utils/cameraHelpers';

const _tmpV3 = new Vector3();
const _tmpQuat = new Quaternion();

// TODO EXTRACT TO HELPER
const valueOrDefault = (value: any, defaultValue: any): any => {
  if (value === undefined) {
    return defaultValue;
  } else {
    return value;
  }
};

const calculateVerticalFov = (verticalFov: number, aspect?: number): number => {
  const limit = 1.4;
  if (aspect && verticalFov) {
    if (aspect < limit) { // portrait
      // threejs has constant vertical FOV,
      // in portrait we want constant horizontal FOV

      const rawFovHorizontal = getHorizontalFov(verticalFov, limit);
      const variableVerticalFov = getVerticalFov(rawFovHorizontal, aspect);

      return variableVerticalFov;
    } else {
      return verticalFov;
    }
  }
  return verticalFov;
};

export const useCameraController = (): void => {
  const currentFSMState = FSMStore(state => state.currentFSMState);
  const { cameraBrain, activeVirtualCamera, setActiveVirtualCamera, rawFovVertical, /* rawFovHorizontal, */ setRawFov/*, isLerping*/, setIsLerping } = cameraStore(state => ({
    cameraBrain: state.cameraBrain,
    rawFovVertical: state.rawFovVertical,
    // rawFovHorizontal: state.rawFovHorizontal,
    setRawFov: state.setRawFov,
    activeVirtualCamera: state.activeVirtualCamera,
    setActiveVirtualCamera: state.setActiveVirtualCamera,
    // isLerping: state.isLerping,
    setIsLerping: state.setIsLerping
  }));
  const { setControlsStoreConfig, setControlsTarget } = controlsStore(state => ({
    setControlsStoreConfig: state.setConfig,
    setControlsTarget: state.setTarget
  }));
  const { viewport: { aspect } } = useThree();
  const previousAspect = useRef<number>(-1);

  //#region Lerping between Cameras

  // const [isLerping, setLerping] = useState<boolean>(false); // replaced by flag in ~cameraStore~ ref below
  const isLerping = useRef<boolean>(false);
  const preparedForLerping = useRef<boolean>(false);

  const lerpDuration = useRef<number>(DefaultValues.lerpDuration);
  const lerpTime = useRef<number>(-1);
  const orbitControlsEnabled = useRef<boolean>(DefaultValues.orbitControlsEnabled);
  const enableRotate = useRef<boolean>(DefaultValues.enableRotate);
  const autoRotate = useRef<boolean>(DefaultValues.autoRotate);
  const enablePan = useRef<boolean>(DefaultValues.enablePan);
  const enableZoom = useRef<boolean>(DefaultValues.enableZoom);
  const minAzimuthAngle = useRef<number>(DefaultValues.minAzimuthAngle);
  const maxAzimuthAngle = useRef<number>(DefaultValues.maxAzimuthAngle);
  const minPolarAngle = useRef<number>(DefaultValues.minPolarAngle);
  const maxPolarAngle = useRef<number>(DefaultValues.maxPolarAngle);
  const groundThreshold = useRef<number | null>(DefaultValues.groundThreshold);
  const previousCamera = useRef<PerspectiveCamera>(new PerspectiveCamera());
  // const currentCamera = useRef<PerspectiveCamera>(new PerspectiveCamera());
  const nextTargetPosition = useRef<Vector3>(new Vector3());

  const prepareForLerping =
    useCallback(() => {
      if (!cameraBrain || !activeVirtualCamera) return;

      lerpDuration.current = valueOrDefault(activeVirtualCamera.userData.tags?.lerpDuration, DefaultValues.lerpDuration);
      orbitControlsEnabled.current = valueOrDefault(activeVirtualCamera.userData.tags?.orbitControls, DefaultValues.orbitControlsEnabled);
      enableRotate.current = valueOrDefault(activeVirtualCamera.userData.tags?.enableRotate, DefaultValues.enableRotate);
      autoRotate.current = valueOrDefault(activeVirtualCamera.userData.tags?.autoRotate, DefaultValues.autoRotate);
      enablePan.current = valueOrDefault(activeVirtualCamera.userData.tags?.enablePan, DefaultValues.enablePan);
      enableZoom.current = valueOrDefault(activeVirtualCamera.userData.tags?.enableZoom, DefaultValues.enableZoom);
      minAzimuthAngle.current = valueOrDefault(activeVirtualCamera.userData.tags?.minAzimuthAngle, DefaultValues.minAzimuthAngle);
      maxAzimuthAngle.current = valueOrDefault(activeVirtualCamera.userData.tags?.maxAzimuthAngle, DefaultValues.maxAzimuthAngle);
      minPolarAngle.current = valueOrDefault(activeVirtualCamera.userData.tags?.minPolarAngle, DefaultValues.minPolarAngle);
      maxPolarAngle.current = valueOrDefault(activeVirtualCamera.userData.tags?.maxPolarAngle, DefaultValues.maxPolarAngle);
      groundThreshold.current = valueOrDefault(activeVirtualCamera.userData.tags?.groundThreshold, DefaultValues.groundThreshold);
      if (activeVirtualCamera.userData.tags?.cameraTarget === undefined)
        tLogStyled(`[useCameraController] Camera target for ${activeVirtualCamera?.name} is undefined !`, logHelper.error); // TODO DEBUG

      nextTargetPosition.current = valueOrDefault(activeVirtualCamera.userData.tags?.cameraTarget?.getWorldPosition(new Vector3()), null); // cameraTarget is Object3D

      previousCamera.current = cameraBrain.clone(); // keep track of previous cam for lerping

      setControlsStoreConfig({ enabled: false, enableDamping: false });
      tLogStyled(`[useCameraController] Camera lerping to ${activeVirtualCamera?.name} in ${lerpDuration.current}s`, logHelper.processing); // TODO DEBUG
      // setIsLerping(true);
      lerpTime.current = 0;
      preparedForLerping.current = true;

    }, [activeVirtualCamera, cameraBrain, setControlsStoreConfig/*, setIsLerping*/]);

  const lerpCameraTransform =
    useCallback((target: PerspectiveCamera, from: PerspectiveCamera, to: PerspectiveCamera, t: number) => {
      // target.position.lerpVectors(from.position, to.position, t);
      target.position.lerpVectors(from.position, to.getWorldPosition(_tmpV3), t);
      target.quaternion.slerpQuaternions(from.quaternion, to.getWorldQuaternion(_tmpQuat), t); // overriden later by target
      target.scale.lerpVectors(from.scale, to.getWorldScale(_tmpV3), t);
      setRawFov(to.fov);
 
      // from.fov already adapted to aspect ratio
      target.fov = MathUtils.lerp(from.fov, calculateVerticalFov(to.fov, aspect), t);
      target.updateProjectionMatrix();

      // tLogStyled(`[useCameraController] Lerping ${t*100}%`, logHelper.processing, from.position, target.position, to.getWorldPosition(_tmpV3)); // TODO DEBUG

      setControlsTarget(calculateTarget(target)); // target 0.01 m in front of the camera
    }, [setControlsTarget, setRawFov, aspect]);

  const copyCameraTransform =
    useCallback((target: PerspectiveCamera, source: PerspectiveCamera) => {
      // position
      target.position.copy(source.getWorldPosition(_tmpV3));

      // rotation
      const cameraTarget = source.userData.tags?.cameraTarget;
      // if (cameraTarget) target.lookAt(cameraTarget.position);
      if (cameraTarget) target.lookAt(cameraTarget.getWorldPosition(_tmpV3)); // TODO TEST
      else target.quaternion.copy(source.getWorldQuaternion(_tmpQuat).clone());
      // console.log(cameraTarget);

      // scale
      target.scale.copy(source.getWorldScale(_tmpV3));
      setRawFov(source.fov);
      target.fov = calculateVerticalFov(source.fov, aspect);
    }, [setRawFov]);

  // FOV management
  useFrame(() => {
    if (!isLerping && cameraBrain && aspect !== previousAspect.current) {
      const fov = calculateVerticalFov(rawFovVertical, aspect);
      if (fov !== cameraBrain.fov) {
        cameraBrain.fov = calculateVerticalFov(rawFovVertical, aspect);
        cameraBrain.updateProjectionMatrix();
      }

      // tLogStyled(`[useCameraController] cameraBrain.fov adapted from ${rawFovVertical} to ${cameraBrain.fov} (aspect = ${aspect})`, logHelper.processing);
    }
    previousAspect.current = aspect;
  });

  // Lerping & copy transform
  useFrame((state, delta) => {
    if (cameraBrain && activeVirtualCamera) {
      // if (isLerping) {
      if (isLerping.current && preparedForLerping.current) {
        // let finishedLerping = !isLerping;
        let finishedLerping = !isLerping.current;

        lerpTime.current += delta; //increment timer once per frame

        // lerping finished
        if (lerpTime.current >= lerpDuration.current) {
          lerpTime.current = lerpDuration.current;
          finishedLerping = true;
        }

        // move camera
        const t = lerpDuration.current === 0 ? 1 : easeOutCubic(lerpTime.current / lerpDuration.current);
        lerpCameraTransform(cameraBrain, previousCamera.current, activeVirtualCamera, t);

        // set target and re-enable controls when finished
        if (finishedLerping) {
          // setLerping(false);
          isLerping.current = false;
          setIsLerping(false); // store value, not used in this script, just to let other components know

          setControlsTarget(nextTargetPosition.current ? nextTargetPosition.current : calculateTarget(activeVirtualCamera));

          setControlsStoreConfig({
            enabled: orbitControlsEnabled.current || DefaultValues.orbitControlsEnabled,
            enableDamping: orbitControlsEnabled.current || DefaultValues.enableDamping, // damping if controls enabled??
            enableRotate: enableRotate.current || DefaultValues.enableRotate,
            enablePan: enablePan.current || DefaultValues.enablePan,
            enableZoom: enableZoom.current || DefaultValues.enableZoom,
            minAzimuthAngle: minAzimuthAngle.current || DefaultValues.minAzimuthAngle,
            maxAzimuthAngle: maxAzimuthAngle.current || DefaultValues.maxAzimuthAngle,
            minPolarAngle: minPolarAngle.current || DefaultValues.minPolarAngle,
            maxPolarAngle: maxPolarAngle.current || DefaultValues.maxPolarAngle,
            autoRotate: autoRotate.current || DefaultValues.autoRotate,
            groundThreshold: groundThreshold.current || DefaultValues.groundThreshold,
            autoRotateSpeed: DefaultValues.autoRotateSpeed
          });
        }
      } else if (isLerping.current && !preparedForLerping.current) {
        tLogStyled(`[useCameraController] Waiting for lerping preparation`, logHelper.subdued); // TODO DEBUG
      } else {
        // if (!orbitControlsEnabled.current)
        //   copyCameraTransform(cameraBrain, activeVirtualCamera);
      }

      cameraBrain.updateProjectionMatrix();
    }
  });

  //#endregion

  // Set isLerping to true to
  useEffect(() => {
    prepareForLerping();
  }, [activeVirtualCamera, prepareForLerping]);

  // Update activeVirtualCamera when FSMState changes
  useEffect(() => {
    // sometimes useFrame() is executed after activeVirtualCamera has changed
    // but before prepareForLerping() and isLerping is set resulting in a camera glitch
    // => so setting isLerping=true before changing activeVirtualCamera as a quick fix
    // setIsLerping(true);
    // setActiveVirtualCamera(currentFSMState);

    preparedForLerping.current = false;
    isLerping.current = setActiveVirtualCamera(currentFSMState);

  }, [currentFSMState, setActiveVirtualCamera/*, setIsLerping*/]);

};

/*** @returns {Vector3} */
const calculateTarget = (camera: PerspectiveCamera, distance = 0.01): Vector3 =>
  (new Vector3()).addVectors(
    camera.getWorldPosition(_tmpV3),
    camera.getWorldDirection(new Vector3()).multiplyScalar(distance)
  );
