import React from 'react';
import {useScaleWithScreenSize, useSizeClass} from 'hooks';
import {ToastContainer} from 'react-toastify';
import Header from 'components/Header/Header';
import Main from 'components/Main/Main';
import DebugOverlay from 'components/Debug/DebugOverlay';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import {useLocation} from 'react-use';

const referenceResolution = {x: 1920, y: 1080}; // prevent rerender?

const App: React.FC = () => {
  const location = useLocation();
  const appRef = useSizeClass();
  useScaleWithScreenSize(
    referenceResolution,
    0.5,
    6,
    16,
    22
  );

  return (
    <div ref={appRef} className="app">
      {/*{process.env.NODE_ENV === 'development' && <DebugOverlay/>}*/}

      {location.search?.includes('debug') && <DebugOverlay/>}

      <Header/>
      <Main/>
      <ToastContainer/>
    </div>
  );
};

export default App;
