import {FSMStore} from 'webgl/stores';
import {useEffect, useState} from 'react';
import {FSMStates} from 'webgl/types/FSMStates';

export const useFullpagePanel = (): [boolean, boolean] => {
  const currentFSMState = FSMStore(state => state.currentFSMState);
  const [fullpage, setFullpage] = useState<boolean>(false);
  const [displayBackground, setDisplayBackground] = useState<boolean>(false);

  // TODO NOT NEEDED FOR ENCOFFREMENT
  //  MAYBE FOR CREDITS?
  useEffect(() => {
    switch (currentFSMState) {
      case FSMStates.webgl.main_sub.savoirPlus: {
        setDisplayBackground(false);
        setFullpage(true);
        break;
      }
      case FSMStates.webgl.cumul: {
        setDisplayBackground(true);
        setFullpage(false);
        break;
      }
      default: {
        setDisplayBackground(false);
        setFullpage(false);
        break;
      }
    }
  }, [currentFSMState]);

  return [fullpage, displayBackground];
};