import create from 'zustand';
import {Object3D} from 'three/src/core/Object3D';
import {AnnotationData} from 'webgl/types/AnnotationData';

type FixedAnnotationStoreProps = {
  fixedAnnotationObjects: Object3D[];
  setFixedAnnotationObjects: (fixedAnnotationObjects: Object3D[]) => void;

  fixedAnnotationDescriptions: AnnotationData[];
  setFixedAnnotationDescriptions: (descriptions: AnnotationData[]) => void;
}

export const fixedAnnotationStore = create<FixedAnnotationStoreProps>((set) => ({
  fixedAnnotationObjects: [],
  setFixedAnnotationObjects: (obj) => set({fixedAnnotationObjects: obj}),

  fixedAnnotationDescriptions: [],
  setFixedAnnotationDescriptions: (descriptions) => set({fixedAnnotationDescriptions: descriptions})
}));