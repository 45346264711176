import React, {useCallback, useEffect} from 'react';
import {EquipmentEntity} from 'webgl/entities/EquipmentEntity';
import {Color, FrontSide} from 'three';
import {useHandleAnimations, useHandleObjectVisibility} from 'webgl/hooks';

type EquipmentState = {
  equipmentEntity: EquipmentEntity;
  castShadow?: boolean
}

const Equipment: React.FC<EquipmentState> = ({equipmentEntity, castShadow = true}) => {
  useHandleAnimations(equipmentEntity);
  useHandleObjectVisibility(equipmentEntity.scene);


  // prepare materials TODO => move to parsing methods?
  useEffect(() => {
    equipmentEntity.scene.traverse(obj => {
      obj.castShadow = castShadow;
      obj.receiveShadow = castShadow;

      // @ts-ignore
      const material = obj.material;
      if (material) {
        material.side = FrontSide;

        // TODO extract to useParseGLTFAssets ???
        // if (material.depthWrite === false) {
        //   // material.visible = true;
        //   // material.transparent = true;
        //   material.roughness = 0;
        //   material.metalness = 1;
        //   material.opacity = 0.2;
        //   obj.castShadow = false;
        //   obj.receiveShadow = castShadow;
        //   // material.color = new Color(0xffff00);
        // }
      }
    });
  }, [castShadow, equipmentEntity]);

  // handle Equipment animations

  const logObj = useCallback(e => console.log(e), []);


  if (!equipmentEntity) return null;
  return (
    <primitive
      // ref={eqRef}
      name={'Equipment Primitive (asset.scene)'}
      object={equipmentEntity.scene}
      position={equipmentEntity.pivotOffsetWorld}
      dispose={null}
    />
  );
};

export default Equipment;
