import React, {useCallback, useMemo, useRef} from 'react';
import {useFullpagePanel, useGetNavigationData} from 'hooks';
import {FSMStore} from 'webgl/stores';

import './SidePanelTitle.scss';
import {ReactComponent as CloseCircleIcon} from 'assets/icons/close-circle.svg';

type SidePanelTitleProps = {
  marker?: string;
  title?: string;
}

const SidePanelTitle: React.FC<SidePanelTitleProps> = ({marker, title}) => {
  const currentFSMState = FSMStore(state => state.currentFSMState);
  const setFSMState = FSMStore(state => state.setFSMState);
  const [fullpage,] = useFullpagePanel();
  const parentState = useMemo(() => currentFSMState?.substring(0, currentFSMState.lastIndexOf('_')) || '', [currentFSMState]); // TODO HACK TO RETRIEVE PARENT STATE USING '_' CHAR...
  const gotoParentState = useCallback(() =>
    parentState && setFSMState(parentState)
    , [parentState, setFSMState]);

  const isPointerDown = useRef<boolean>(false);
  const onPointerDown = useCallback(() => isPointerDown.current = true, []);
  const onPointerUp = useCallback(() => {
    if (!isPointerDown.current) return;
    gotoParentState();
    isPointerDown.current = false;
  }, [gotoParentState]);

  const isSubState = useMemo(() => (currentFSMState && currentFSMState.split('.').length >= 3) || false, [currentFSMState]);

  return (
    <div className={`side-panel-title  ${fullpage ? 'fullpage' : 'right'}`}
      style={!title ? {paddingRight: '0.625rem'} : void 0}>
      <svg className={`side-panel-title-marker${isSubState ? ' substate' : ''}`}>
        <circle id="backCircle"/>
        <circle id="frontCircle"/>
        <text x="50%" y="50%" dy=".3em">{marker}</text>
      </svg>

      {title && <div className={`side-panel-title-text${isSubState ? ' substate' : ''}`}>{title}</div>}
      {isSubState && <CloseCircleIcon className={'side-panel-close'} onPointerDown={onPointerDown} onPointerUp={onPointerUp}/>}
    </div>
  );
};

export default SidePanelTitle;